import { createSlice } from "@reduxjs/toolkit";
import { callApi } from '../Helper/helper';
import { apiFail, apiSuccess } from './mainSlice';
const homeSlice = createSlice({
    name: "homeSlice",
    initialState:{
        hightPriority: [],
        jobTitle:[]
    },
    reducers: {
        hightPriority: (state, action) => {
            state.hightPriority = action.payload.data;
        },
        jobTitle : (state,action) => {
            state.jobTitle = action.payload.data
        }
    }
})
const { hightPriority,jobTitle } = homeSlice.actions;
export default homeSlice.reducer;

export const hightPriorityJobs = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/user/allJobsList', values);
        dispatch(hightPriority(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}

export const getJobTitleList = () => async dispatch => {
    try {
        const res = await callApi('post', '/user/allJobTitleList');
        dispatch(jobTitle(res.data))
    } catch (e) {
        dispatch(apiFail(e));
    }
}