import { Link } from "react-router-dom";

// CSS
import "../../assets/css/layout/Footer.css";

// Icons
import IcFacebook from '../../assets/images/icons/ic-facebook.svg'
import IcTwitter from '../../assets/images/icons/ic-twitter.svg'
import IcLinkedIn from '../../assets/images/icons/ic-linkedin.svg'
import IcIg from '../../assets/images/icons/ic-ig.svg'

// Images
import logoWhite from '../../assets/images/logos/logo-white.svg';


function Footer() {
    return (
        <>
            <footer>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12">
                                <div className="foot-info">
                                    <Link className="foot-logo" to="/">
                                        <img src={logoWhite} alt="" />
                                    </Link>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero, fermentum pretium purus in aenean metus, in fermentum nunc. Eu consequat aliquet dictum nunc eget pellentesque vel mi erat.</p>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-6">
                                <div className="foot-links">
                                    <h4>Company</h4>
                                    <ul>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><Link to="/">Contact Us</Link></li>
                                        <li><Link to="/">Services</Link></li>
                                        <li><Link to="/">Blog</Link></li>
                                        <li><Link to="/">Team</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-6">
                                <div className="foot-links">
                                    <h4>For Jobs</h4>
                                    <ul>
                                        <li><Link to="/">Browse Categories</Link></li>
                                        <li><Link to="/">Browse Jobs</Link></li>
                                        <li><Link to="/">Job Details</Link></li>
                                        <li><Link to="/">Bookmark Jobs</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-6">
                                <div className="foot-links">
                                    <h4>For Candidates</h4>
                                    <ul>
                                        <li><Link to="/">Candidate List</Link></li>
                                        <li><Link to="/">Candidate Grid</Link></li>
                                        <li><Link to="/">Candidate Details</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-6">
                                <div className="foot-links">
                                    <h4>Condition</h4>
                                    <ul>
                                        <li><Link to="/" >Terms & Condition</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="d-sm-flex justify-content-sm-between align-items-sm-center flex-sm-row-reverse">
                            <div className="footer-social">
                                <Link to="/"><img src={IcFacebook} alt="" /></Link>
                                <Link to="/"><img src={IcTwitter} alt="" /></Link>
                                <Link to="/"><img src={IcLinkedIn} alt="" /></Link>
                                <Link to="/"><img src={IcIg} alt="" /></Link>
                            </div>
                            <p>&copy; 2022 Inannaa.All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;