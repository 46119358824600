import { createSlice } from "@reduxjs/toolkit";
import { callApi } from '../Helper/helper'
import { apiFail, apiSuccess } from './mainSlice'
const authSlice = createSlice({
    name: "authSlice",
    initialState: {
        loggedInUser: {
            isLoading: false,
            data: {
                _id: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                type: ""
            }
        },
    },
    reducers: {
        authProfile: (state, action) => {
            state.loggedInUser = {
                isLoading: true,
                data: action.payload.data
            }
        },
        logoutSuccess: (state, action) => {
            state.loggedInUser = {
                isLoading: false,
                data: {
                    _id: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    type: ""
                }
            }
        },
    }
})
const { authProfile, logoutSuccess } = authSlice.actions
export default authSlice.reducer

export const login = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/user/login', values);
        localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, res.data.token)
        delete res.data.token
        dispatch(apiSuccess(res.data))
        dispatch(authProfile(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}

export const googlelogin = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/user/googleLogin', values);
        localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, res.data.data.token)
        delete res.data.data.token
        dispatch(apiSuccess(res.data))
        dispatch(authProfile(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}

export const logout = () => async dispatch => {
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN)
    dispatch(logoutSuccess());
}
export const getProfile = () => async dispatch => {
    try {
        const res = await callApi('post', '/profile', {});
        dispatch(authProfile(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
export const updateProfile = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/update-profile', values, { 'Content-Type': 'multipart/form-data' })
        dispatch(authProfile(res.data))
        dispatch(apiSuccess(res.data));
    } catch (e) {
        dispatch(apiFail(e))
    }

}
export const updatePassword = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/update-password', values);
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
export const forgetPassword = (value) => async dispatch => {
    try {
        let res = await callApi('post', '/forget-password', value)
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}
export const resetPassword = (value) => async dispatch => {
    try {
        let res = await callApi('post', '/reset-password', value)
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    }
}

