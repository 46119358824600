import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Redusers/authSlice";
import mainSlice from "./Redusers/mainSlice";
import homeSlice from "./Redusers/homeSlice";
import jobListingSlice from "./Redusers/jobListingSlice";

const store = configureStore({
    reducer: {
        main: mainSlice,
        auth: authSlice,
        home: homeSlice,
        jobListing: jobListingSlice
    }
})
export default store