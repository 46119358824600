import React from 'react';
import { lazy } from "react";
import { BrowserRouter, withRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';


// Global CSS Files
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./assets/css/style.css";
import "./assets/css/responsive.css";

// Layouts
import DefaultLayout from './layouts/DefaultLayout';

// Common Pages
const Login = lazy(() => import('./views/Common/Login'));
const ForgotPassword = lazy(() => import('./views/Common/ForgotPassword'));
const ResetPassword = lazy(() => import('./views/Common/ResetPassword'));
const ChangePassword = lazy(() => import('./views/Common/ChangePassword'));
const Otp = lazy(() => import('./views/Common/Otp'));
const AboutUs = lazy(() => import('./views/Common/AboutUs'));
const TermsConditions = lazy(() => import('./views/Common/TermsConditions'));
const ContactUs = lazy(() => import('./views/Common/ContactUs'));


// Job Seeker Panel Pages
const Home = lazy(() => import('./views/JobSeeker/Home'));
const Signup = lazy(() => import('./views/JobSeeker/Signup'));

const JobListing = lazy(() => import('./views/JobSeeker/JobListing'));

const JobDetails = lazy(() => import('./views/JobSeeker/JobDetails'));
const Resume = lazy(() => import('./views/JobSeeker/Resume'));
const PersonalInformation = lazy(() => import('./views/JobSeeker/PersonalInformation'));
const ProfessionalDetails = lazy(() => import('./views/JobSeeker/ProfessionalDetails'));
const AddSkills = lazy(() => import('./views/JobSeeker/AddSkills'));
const SavedJobs = lazy(() => import('./views/JobSeeker/SavedJobs'));
const Notifications = lazy(() => import('./views/JobSeeker/Notifications'));
const SubscriptionPlan = lazy(() => import('./views/JobSeeker/SubscriptionPlan'));
const EditProfile = lazy(() => import('./views/JobSeeker/EditProfile'));
const ViewProfile = lazy(() => import('./views/JobSeeker/ViewProfile'));
const ReleventExperience = lazy(() => import('./views/JobSeeker/ReleventExperience'));
const Chat = lazy(() => import('./views/JobSeeker/Chat'));
const Activities = lazy(() => import('./views/JobSeeker/Activities'));
const MyResume = lazy(() => import('./views/JobSeeker/MyResume'));
const Setting = lazy(() => import('./views/JobSeeker/Setting'));


function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <React.Suspense fallback={'loader'}>
          <Routes>
            {/* before login */}
            <Route path="/login" element={<Login />} ></Route>
            <Route path="/signup" element={<Signup />} ></Route>
            <Route path="/forgotPassword" element={<ForgotPassword />} ></Route>
            <Route path="/otp" element={<Otp />} ></Route>
            <Route path="/resetPassword" element={<ResetPassword />} ></Route>
            <Route path="/changePassword" element={<ChangePassword />} ></Route>

            {/* after login */}
            <Route path="/" element={<DefaultLayout />} >
              <Route path="/" element={<Home />} ></Route>
              <Route path="/editProfile" element={<EditProfile />} ></Route>
              <Route path="/viewProfile" element={<ViewProfile />} ></Route>
              
              <Route path="/resume" element={<Resume />} ></Route>
              <Route path="/createResume/PersonalInformation" element={<PersonalInformation />} ></Route>
              <Route path="/createResume/ProfessionalDetails" element={<ProfessionalDetails />} ></Route>
              <Route path="/createResume/addSkills" element={<AddSkills />} ></Route>
              <Route path="/createResume/releventExperience" element={<ReleventExperience />} ></Route>
              
              <Route path="/notifications" element={<Notifications />} ></Route>
              <Route path="/subscriptionPlan" element={<SubscriptionPlan />} ></Route>
              <Route path="/messages" element={<Chat />} ></Route>
              <Route path="/activities" element={<Activities />} ></Route>
              <Route path="/myResume" element={<MyResume />} ></Route>
              <Route path="/setting" element={<Setting />} ></Route>
              <Route path="/aboutus" element={<AboutUs />} ></Route>
              <Route path="/termsConditions" element={<TermsConditions />} ></Route>
              <Route path="/contactus" element={<ContactUs />} ></Route>

              <Route path="/savedjobs" element={<SavedJobs />} ></Route>

              <Route path="/all-jobs-list" element={<JobListing />} ></Route>
              <Route path="/jobDetails/:jobId" element={<JobDetails />} ></Route>
              <Route path="/joblist/:title" exact={false} element={<JobListing />} ></Route>
              <Route path="/joblist/:title/:location" exact={false} element={<JobListing />} ></Route>

            </Route>
          </Routes>
        </React.Suspense>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
