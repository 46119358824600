import { Link,useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import Dropdown from 'react-bootstrap/Dropdown'
import { useClickOutside } from "../../helper/useClickOutside";
import { useDispatch, useSelector } from 'react-redux';

// CSS
import "../../assets/css/layout/Header.css";

// Images
import Logo from "../../assets/images/logos/logo.svg";
import profileImg from "../../assets/images/profile_user.jpg";

import { logout } from '../../Redusers/authSlice';

function Header({ authorize }) {
    const history = useNavigate();
    const dispatch = useDispatch();

    const [isNavMovedUp, setIsNavMovedUp] = useState(false);
    const [isNavTransitioned, setIsNavTransitioned] = useState(false);
    const [isNavSticky, setIsNavSticky] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const loggedInUser = useSelector(state => state.auth.loggedInUser)

    const mainNavRef = useRef();

    useEffect(() => {

        const handleScroll = () => {
            const offsetTop = window.pageYOffset;
            offsetTop > 150 ? setIsNavMovedUp(true) : setIsNavMovedUp(false);
            offsetTop > 250 ? setIsNavTransitioned(true) : setIsNavTransitioned(false);
            offsetTop > 400 ? setIsNavSticky(true) : setIsNavSticky(false);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener("scroll", handleScroll);
        }

    })

    const handleClick = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    }

    const handleClose = () => {
        setIsMobileNavOpen(false);
    }

    // Hide Mobile Sidebar on click outside of navigation
    useClickOutside(mainNavRef, () => setIsMobileNavOpen(false));


    return (
        <header className={
            classNames(
                'navbar-sticky',
                isNavMovedUp && 'navbar-sticky-moved-up',
                isNavTransitioned && 'navbar-sticky-transitioned',
                isNavSticky && 'navbar-sticky-on',
                isMobileNavOpen && 'mobile-navigation--open'
            )
        }>
            <div className="container">
                <Link className="brand-logo" to="/">
                    <img src={Logo} alt="" />
                </Link>

                {
                    authorize
                        ?
                        <div className="header-search">
                            <form action="">
                                <div className="form-group search">
                                    <input type="text" className="form-control" placeholder="search" />
                                </div>
                            </form>
                        </div>
                        :
                        null
                }

                <div className="main-navigation" ref={mainNavRef}>
                    <span className="nav-close-btn" onClick={handleClose}><i className="bi bi-x-circle"></i></span>
                    <ul>
                        {
                            authorize

                                ?
                                <>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/activities">Activities</Link></li>
                                    <li><Link to="/messages">Message</Link></li>
                                    <li><Link to="/resume">Resume</Link></li>
                                    <li><Link to="/savedJobs">Saved jobs</Link></li>
                                </>

                                :

                                <>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/jobsList">Jobs</Link></li>
                                </>
                        }


                    </ul>
                </div>
                <ul className="user-controls">

                    {
                        !authorize

                            ?

                            <>
                                <li className="login-employer">
                                    <Link to="/login" className="button button-blue">Employer Mode</Link>
                                </li>
                                <li className="login-jobseeker">
                                    <Link to="/login" ><i className="bi bi-person"></i></Link>
                                </li>
                                <li className="nav-toggler">
                                    <span onClick={handleClick}><i className="bi bi-list"></i></span>
                                </li>
                            </>
                            :
                            <>
                                <li>
                                    <Link to="/notifications" className="notification">
                                        <span className="count">5</span>
                                        <i className="bi bi-bell-fill"></i>
                                    </Link>
                                </li>

                                <Dropdown className="logged-user" align="end" as="li">
                                    <Dropdown.Toggle variant="" id="dropdown-split-basic" >
                                        <img src={profileImg} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="user-info">
                                            <div className="user-img">
                                                <img src={profileImg} alt="" />
                                            </div>
                                            <div>
                                                <h4>John Doe</h4>
                                                <p>Marketing Manager</p>
                                            </div>
                                        </div>
                                        <Link to="/viewProfile" className="dropdown-item">My Profile</Link>
                                        <Link to="/myResume" className="dropdown-item">My Resume</Link>
                                        <Link to="/subscriptionPlan" className="dropdown-item">My Plans</Link>
                                        <Link to="/setting" className="dropdown-item">Settings</Link>
                                        <Link 
                                            to="#"
                                            className="dropdown-item"
                                            onClick={evt =>{
                                                dispatch(logout())
                                              }}
                                            >Logout</Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <li className="nav-toggler">
                                    <span onClick={handleClick}><i className="bi bi-list"></i></span>
                                </li>
                            </>
                    }

                </ul>
            </div>
        </header>
    )
}


export default Header;