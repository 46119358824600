import { Outlet } from "react-router-dom";
import Header from "../component/layout/Header";
import Footer from "../component/layout/Footer";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from 'react-redux';


function DefaultLayout() {
    const loggedInUser = useSelector(state => state.auth.loggedInUser)
    const [authorize, setAuthorize] = useState(false);

    useEffect(()=>{
        if(localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)){
            setAuthorize(true);
        }else{
            setAuthorize(loggedInUser.isLoading)
        }
    },[loggedInUser])
    return (
        <>
            <Header authorize = {authorize} />
            <main className="main" role="main">
                <Outlet/>
            </main>
            <Footer />
        </>
    )
}

export default DefaultLayout;