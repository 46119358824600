import { createSlice } from "@reduxjs/toolkit";
import { callApi } from '../Helper/helper';
import { apiFail, apiSuccess } from './mainSlice';
const jobListingSlice = createSlice({
    name: "jobListingSlice",
    initialState:{
        jobListings: [],
        jobDetail: {}
    },
    reducers: {
        jobListings: (state, action) => {
            state.jobListings = action.payload.data;
        },
        jobDetail:(state,action) => {
            state.jobDetail = action.payload.data;
        }
    }
})
const { jobListings,jobDetail } = jobListingSlice.actions;
export default jobListingSlice.reducer;

export const getAllJobs = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/user/allJobsList',values);
        dispatch(jobListings(res.data))
    } catch (e) {
        dispatch(apiFail(e));
    }
}

export const getSearchJobs = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/user/searchJobs',values);
        dispatch(jobListings(res.data))
    } catch (e) {
        dispatch(apiFail(e));
    }
}

export const getJobDetails = (values) => async dispatch => {
    try {
        const res = await callApi('post', '/user/jobDetail',values);
        dispatch(jobDetail(res.data))
    } catch (e) {
        dispatch(apiFail(e));
    }
}